import React from "react";
import { graphql, navigate, PageProps as GatsbyPageProps } from "gatsby";
import { PageDataQueryParams } from "~/types/pageContext";
import { PageData } from "~/types/pageData";
import { headerPrimaryHeight } from "~/components/Header/constants";
import { Container, Box } from "~/components/Layout";
import { Text, RichText } from "~/components/Text";
import { BackgroundImage } from "~/components/Image";
import { Asset } from "~/types/asset";
import Button from "~/components/Button";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface ErrorPageData extends PageData {
  page: {
    heading: string;
    description: string;
    background_image: Asset[];
    foreground_image: Asset;
  };
}

// Because this is a "Page" in gatsby, not a standard component, the props must extend the PageProps
type NotFoundPageProps = GatsbyPageProps<ErrorPageData, PageDataQueryParams>;

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data: { page } }) => {
  const { t } = useTranslation();
  const { heading, description, background_image, foreground_image } = page;
  const takeMeHome = (): void => {
    navigate("/");
  };

  return (
    <BackgroundImage image={background_image}>
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        height={`calc(100vh - ${headerPrimaryHeight}px)`}
      >
        <Box as="img" width="100%" maxWidth="20rem" mt="-10vh" src={foreground_image.url} />
        <Text as="h1" typeStyle="heading-3xl" my="base">
          {heading}
        </Text>
        <RichText>{description}</RichText>
        <Button onClick={takeMeHome} variant="primary" mt="base">
          {t("take_me_home")}
        </Button>
      </Container>
    </BackgroundImage>
  );
};

export const query = graphql`
  query($language: String!, $uid: String!) {
    ...MetaFragment
    ...HeaderFragment

    page: contentstack404(publish_details: { locale: { eq: $language } }, uid: { eq: $uid }) {
      __typename
      meta {
        page_title
        page_description
      }
      background_image {
        ...Image
      }
      foreground_image {
        ...Image
      }
      heading
      description
    }
    # Mandatory query for i18next. All pages need this query.
    ...TranslationQueryFragment
  }
`;

export default NotFoundPage;
